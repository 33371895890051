import Vue from 'vue';
import {mapMutations, mapState} from 'vuex';

export default Vue.extend({
  name: 'Notification',

  computed: {
    ...mapState(['notification']),
  },

  methods: {
    ...mapMutations(['notificationManager']),

    handleClose() {
      this.notificationManager({
        status: false,
        title: '',
        text: '',
      });
    },
  },
});
